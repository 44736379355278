<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ setupEstrategia.symbolY.valor }}
                </h2>
                <span>Ativo Y</span>
              </div>
            </b-col>
            <b-col v-if="setupEstrategia.symbolX && setupEstrategia.symbolX.valor">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ setupEstrategia.symbolX.valor }}
                </h2>
                <span>Ativo X</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ setupEstrategia.estrategia.valor }}
                </h2>
                <span>Estratégia</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ setupEstrategia.corretora.valor }}
                </h2>
                <span>Corretora</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.dataInicioBT }}
                </h2>
                <span>Data Início</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.dataFimBT }}
                </h2>
                <span>Data Fim</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="backtest.id">
      <b-col cols="12">
        <b-card-actions
          :title="'ID ' + backtest.id"
          action-collapse
        >
          <b-row>
            <b-col cols="6">
              <b-col cols="12">
                <label
                  for="rating-lg-no-border"
                  class="mr-1"
                >
                  Score
                </label>
                <b-form-rating
                  id="rating-lg-no-border"
                  v-model="backtest.score"
                  no-border
                  inline
                  variant="warning"
                  size="lg"
                  stars="20"
                  show-value
                />
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <div class="form-label-group">
                  <b-form-textarea
                    id="textarea"
                    v-model="backtest.observacao"
                    rows="3"
                    placeholder="Observação"
                  />
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="salvarDadosBacktest"
                >
                  Salvar
                </b-button>
              </b-col>
            </b-col>
            <b-col cols="6">
              <vue-json-pretty
                :data="setupEstrategia"
                :virtual="true"
                height="250"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>

      <b-col
        v-for="grafico in graficos"
        :key="grafico.id"
        cols="12"
      >
        <b-card :title="grafico.nome">
          <div class="chart-container">
            <LWChart
              :grafico="grafico"
              :series="grafico.series"
              :series-update="grafico.series"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8">
        <b-card>
          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.depositoInicial, 2, true) }}
                </h2>
                <span>Depósito</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.lucroLiquido, 2, true) }}
                </h2>
                <span>Lucro Líquido</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.rentabilidadePeriodo, 2) }}<span class="font-medium-3">%</span>
                </h2>
                <span>Rentabilidade Período</span>
              </div>
            </b-col>
            <b-col
              xl="3"
              sm="6"
            >
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.rentabilidadeMensalMedia, 2) }}<span class="font-medium-3">%</span>
                </h2>
                <span>Rentabilidade Média Mensal</span>
              </div>
            </b-col>
            <b-col
              xl="3"
              sm="6"
            >
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.qtdMesesNegativos }}
                </h2>
                <span>Qtd Meses Negativo</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.expectativaMatematica, 2) }}
                </h2>
                <span>Expectativa</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.indiceSharpe, 2) }}
                </h2>
                <span>Índice Sharpe</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.pontuacaoFuncaoBackTeste) }}
                </h2>
                <span>Pontuação</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="8">
        <b-card>
          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.fatorLucro, 2, true) }}
                </h2>
                <span>Fator Lucro</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.lucroBruto, 2, true) }}
                </h2>
                <span>Lucro Bruto</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.perdaBruta, 2, true) }}
                </h2>
                <span>Perda Bruta</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.custosOperacionais, 2, true) }}
                </h2>
                <span>Custos Operacionais</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.payoff, 2) }}
                </h2>
                <span>Payoff</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.mediaLucroOperacoes, 2, true) }}
                </h2>
                <span>Média Lucro <br> por Operação</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.mediaPerdaOperacoes, 2, true) }}
                </h2>
                <span>Média Perda <br> por Operação</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="8">
        <b-card>
          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.taxaAcerto }}<span class="font-medium-3">%</span>
                </h2>
                <span>Taxa Acerto</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.taxaErro }}<span class="font-medium-3">%</span>
                </h2>
                <span>Taxa Erro</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.qtdTotalOperacoes }}
                </h2>
                <span>Qtd Total Operações</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.qtdOperacoesLong }} <span class="font-medium-3">({{ backtest.percentualOperacoesLong }}%)</span>
                </h2>
                <span>Operações Long</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.qtdOperacoesShort }} <span class="font-medium-3">({{ backtest.percentualOperacoesShort }}%)</span>
                </h2>
                <span>Operações Short</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.qtdGanhosConsecutivos }}
                </h2>
                <span>Qtd Ganhos Consecutivos</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.qtdPerdasConsecutivas }}
                </h2>
                <span>Qtd Perdas Consecutivas</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.operacaoMaiorDuracaoHoras }}
                </h2>
                <span>Operação Maior Duração (Horas)</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.duracaoMediaHoras }}
                </h2>
                <span>Duração Média (Horas)</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ backtest.maiorPeriodoEstagnacaoDias }} dias <br>
                  <small>até
                    <span class="font-weight-bolder">{{ formatDateTimeSegundoBR(new Date(backtest.timeMaiorPeriodoEstagnacaoDias)) }}</span>
                  </small>
                </h2>
                <span>Maior Estagnação</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.maiorDrawDownSaldoAbsoluto, 2, true) }}  <br>
                  <small>em
                    <span class="font-weight-bolder">{{ formatDateTimeSegundoBR(new Date(backtest.timeMaiorDrawDownSaldoAbsoluto)) }}</span>
                  </small>
                </h2>
                <span>Maior DrawDown <br> Saldo Abs</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.maiorDrawDownSaldoPercentual, 2) }}<span class="font-medium-3">%</span> <br>
                  <small>em
                    <span class="font-weight-bolder">{{ formatDateTimeSegundoBR(new Date(backtest.timeMaiorDrawDownSaldoPercentual)) }}</span>
                  </small>
                </h2>
                <span>Maior DrawDown <br> Saldo %</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.maiorDrawDownCapitalLiquidoAbsoluto, 2, true) }} <br>
                  <small>em
                    <span class="font-weight-bolder">{{ formatDateTimeSegundoBR(new Date(backtest.timeMaiorDrawDownCapitalLiquidoAbsoluto)) }}</span>
                  </small>
                </h2>
                <span>Maior DrawDown <br>
                  Capital Líquido Abs</span>
              </div>
            </b-col>
            <b-col>
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ formataValor(backtest.maiorDrawDownCapitalLiquidoPercentual, 2) }}<span class="font-medium-3">%</span> <br>
                  <small>em
                    <span class="font-weight-bolder">{{ formatDateTimeSegundoBR(new Date(backtest.timeMaiorDrawDownCapitalLiquidoPercentual)) }}</span>
                  </small>
                </h2>
                <span>Maior DrawDown <br> Capital %</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card title="Risco Ruína">
          <b-table
            :items="JSON.parse(backtest.riscoRuina)"
            responsive
            thead-class="hidden_header"
            primary-key="id"
            show-empty
            empty-text="Nenhum registro encontrado"
            striped
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card title="Rentabilidade Mensal">
          <b-table
            :items="JSON.parse(backtest.rentabilidadeMensal)"
            responsive
            :fields="tableRentabilidadeMensal"
            primary-key="id"
            show-empty
            empty-text="Nenhum registro encontrado"
            striped
          >
            <template #cell(rentabilidade)="data">
              {{ formataValor(data.value, 2) }}%
            </template>

            <template #cell(saldoInicial)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(saldoFinal)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(perdas)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(lucros)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(taxaAcertoMes)="data">
              {{ formataValor(data.value, 2) }}%
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="backtest.operacoesPair">
      <b-col>
        <b-card title="Operações Pair">
          <b-table
            ref="refListTableOperacoesPair"
            :items="itemsOperacoesPair"
            responsive
            :fields="tableOperacoesPair"
            :per-page="perPageOperacaoPair"
            :current-page="currentPageOperacaoPair"
            primary-key="bilhete"
            show-empty
            empty-text="Nenhum registro encontrado"
            striped
            sort-by.sync="bilhete"
            sort-desc.sync="false"
          >
            <template #cell(maiorLucroAberto)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(timeMaiorLucroAberto)="data">
              {{ formatDateTimeSegundoBR(data.value) }}
            </template>

            <template #cell(maiorPrejuizoAberto)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(timeMaiorPrejuizoAberto)="data">
              {{ formatDateTimeSegundoBR(data.value) }}
            </template>

            <template #cell(percentualSobreSaldo)="data">
              {{ formataValor(data.value, 2) }}%
            </template>

            <template #cell(duracao)="data">
              {{ getDuracaoHoraMinuto(data.value) }}
            </template>

            <template #cell(pnl)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(pnlLiquido)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(custoTotal)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

          </b-table>

          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Mostrando {{ paginationMetaOperacoesPair.from }} a {{ paginationMetaOperacoesPair.to }} de {{ paginationMetaOperacoesPair.of }} registros</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPageOperacaoPair"
                  :total-rows="itemsTotalOperacoesPair"
                  :per-page="perPageOperacaoPair"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="backtest.operacoes">
      <b-col>
        <b-card title="Operações">
          <b-table
            ref="refListTableOperacoes"
            :items="itemsOperacoes"
            :per-page="perPageOperacao"
            :current-page="currentPageOperacao"
            responsive
            :fields="tableOperacoes"
            primary-key="bilhete"
            show-empty
            empty-text="Nenhum registro encontrado"
            striped
            sort-by.sync="bilhete"
            sort-desc.sync="false"
          >

            <template #cell(maiorLucroAberto)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(timeMaiorLucroAberto)="data">
              {{ formatDateTimeSegundoBR(data.value) }}
            </template>

            <template #cell(maiorPrejuizoAberto)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(timeMaiorPrejuizoAberto)="data">
              {{ formatDateTimeSegundoBR(data.value) }}
            </template>

            <template #cell(percentualSobreSaldo)="data">
              {{ formataValor(data.value, 2) }}%
            </template>

            <template #cell(duracao)="data">
              {{ getDuracaoHoraMinuto(data.value) }}
            </template>

            <template #cell(pnl)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(pnlLiquido)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(custoTotal)="data">
              {{ formataValor(data.value, 2, true) }}
            </template>

            <template #cell(detalhe)="row">
              <b-button
                v-model="row.detailsShowing"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                @click="row.toggleDetails"
              >
                {{ row.detailsShowing ? 'Fechar' : 'Mostrar' }}
              </b-button>

            </template>

            <template #row-details="row">
              <b-card title="Ordens">
                <b-row class="mb-2">
                  <b-table
                    :fields="tableOperacoesOrdens"
                    responsive
                    sort-by.sync="time"
                    sort-desc.sync="false"
                    :items="row.item.entradas.concat(row.item.saidas)"
                  >
                    <template #cell(time)="data">
                      {{ formatDateTimeSegundoBR(data.value) }}
                    </template>
                  </b-table>
                </b-row>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="row.toggleDetails"
                >
                  Fechar
                </b-button>
              </b-card>
            </template>

          </b-table>

          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Mostrando {{ paginationMetaOperacoes.from }} a {{ paginationMetaOperacoes.to }} de {{ paginationMetaOperacoes.of }} registros</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPageOperacao"
                  :total-rows="itemsTotalOperacoes"
                  :per-page="perPageOperacao"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BRow, BCard, BCol, BTable, BPagination, BButton, BFormRating, BFormTextarea, BFormInput, BForm,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import store from '@/store'
import { formatDateTimeSegundoBR } from '@/utils/filter'
import utilsMixin from '@/mixins/utilsMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import LWChart from '@/layouts/components/custom/LWChart2.vue'
import Ripple from 'vue-ripple-directive'
import backtesterStoreModule from './backtestStoreModule'

const BACKTESTER_STORE_MODULE_NAME = 'backtest'

/* eslint-disable */

export default {

  components: {
    VueJsonPretty,
    LWChart,
    BRow,
    BCard,
    BCol,
    BTable,
    BCardActions,
    BPagination,
    BButton,
    BFormRating,
    BFormInput,
    BFormTextarea,
    BForm,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatDateTimeSegundoBR,
      backtest: [],

      tableRentabilidadeMensal: [
        { key: 'mesAno', label: 'Mês/Ano' },
        { key: 'rentabilidade', label: 'Rentabilidade' },
        { key: 'saldoInicial', label: 'Saldo Inicial' },
        { key: 'saldoFinal', label: 'Saldo Final' },
        { key: 'perdas', label: 'Perdas' },
        { key: 'lucros', label: 'Lucros' },
        { key: 'qtdGanhosMes', label: 'Qtd Ganhos' },
        { key: 'qtdPerdasMes', label: 'Qtd Perdas' },
        { key: 'qtdTotalOperacoes', label: 'Qtd Total Operações' },
        { key: 'taxaAcertoMes', label: 'Taxa Acerto' },
      ],

      tableOperacoesOrdens: [
        { key: 'time', label: 'Data'},
        { key: 'direcao', label: 'Direção'},
        { key: 'lote', label: 'Lote'},
        { key: 'preco', label: 'Preço'},
        { key: 'custo', label: 'Custo'},
      ],

      tableOperacoesPair: [
        { key: 'bilhete', label: 'ID', sortable: true },
        { key: 'maiorLucroAberto', label: 'Maior Lucro Aberto', sortable: true },
        { key: 'timeMaiorLucroAberto', label: 'Data Maior Lucro Aberto', sortable: true },
        { key: 'maiorPrejuizoAberto', label: 'Maior Prejuízo Aberto', sortable: true },
        { key: 'timeMaiorPrejuizoAberto', label: 'Data Maior Prejuízo Aberto', sortable: true },
        { key: 'percentualSobreSaldo', label: 'Percent. Sobre saldo', sortable: true },
        { key: 'direcao', label: 'Direção', sortable: true },
        { key: 'duracao', label: 'Duração', sortable: true },
        { key: 'pnl', label: 'PNL', sortable: true },
        { key: 'pnlLiquido', label: 'PNl Líquido', sortable: true },
        { key: 'custoTotal', label: 'Custo Total', sortable: true },
        { key: 'detalhe', label: 'Detalhes',},
      ],
      currentPageOperacaoPair: 1,
      perPageOperacaoPair: 50,

      tableOperacoes: [
        { key: 'bilhete', label: 'ID', sortable: true },
        { key: 'maiorLucroAberto', label: 'Maior Lucro Aberto', sortable: true },
        { key: 'timeMaiorLucroAberto', label: 'Data Maior Lucro Aberto', sortable: true },
        { key: 'maiorPrejuizoAberto', label: 'Maior Prejuízo Aberto', sortable: true },
        { key: 'timeMaiorPrejuizoAberto', label: 'Data Maior Prejuízo Aberto', sortable: true },
        { key: 'percentualSobreSaldo', label: 'Percent. Sobre saldo', sortable: true },
        { key: 'direcao', label: 'Direção', sortable: true },
        { key: 'duracao', label: 'Duração', sortable: true },
        { key: 'pnl', label: 'PNL', sortable: true },
        { key: 'pnlLiquido', label: 'PNl Líquido', sortable: true },
        { key: 'custoTotal', label: 'Custo Total', sortable: true },
        { key: 'detalhe', label: 'Detalhes',},
      ],
      currentPageOperacao: 1,
      perPageOperacao: 50,
    }
  },

  computed: {
    setupEstrategia() {
      if(!this.backtest.setupEstrategiaJson)
        return null
      return JSON.parse(this.backtest.setupEstrategiaJson)
    },

    graficos() {
      if(!this.backtest.plotResultado)
        return null
      return JSON.parse(this.backtest.plotResultado).graficos
    },

    itemsOperacoesPair() {
      return JSON.parse(this.backtest.operacoesPair)
    },

    itemsTotalOperacoesPair() {
      return this.itemsOperacoesPair.length
    },

    paginationMetaOperacoesPair() {
      const localItemsCount = this.$refs.refListTableOperacoesPair ? this.$refs.refListTableOperacoesPair.localItems.length : 0
      return {
        from: this.perPageOperacaoPair * (this.currentPageOperacaoPair - 1) + (localItemsCount ? 1 : 0),
        to: this.perPageOperacaoPair * (this.currentPageOperacaoPair - 1) + localItemsCount,
        of: this.itemsTotalOperacoesPair,
      }
    },

    itemsOperacoes() {
      return JSON.parse(this.backtest.operacoes)
    },

    itemsTotalOperacoes() {
      return this.itemsOperacoes.length
    },

    paginationMetaOperacoes() {
      const localItemsCount = this.$refs.refListTableOperacoes ? this.$refs.refListTableOperacoes.localItems.length : 0
      return {
        from: this.perPageOperacao * (this.currentPageOperacao - 1) + (localItemsCount ? 1 : 0),
        to: this.perPageOperacao * (this.currentPageOperacao - 1) + localItemsCount,
        of: this.itemsTotalOperacoes,
      }

    },

  },

  watch: {
  },

  created() {
    if (!store.hasModule(BACKTESTER_STORE_MODULE_NAME)) store.registerModule(BACKTESTER_STORE_MODULE_NAME, backtesterStoreModule)
    this.getBacktest(this.$route.params.id)
  },

  beforeDestroy() {
    if (store.hasModule(BACKTESTER_STORE_MODULE_NAME)) store.unregisterModule(BACKTESTER_STORE_MODULE_NAME)
  },

  methods: {

    salvarDadosBacktest() {

      let payload = {
        idBacktest: this.backtest.id,
        score: this.backtest.score,
        observacao: this.backtest.observacao
      }

      this.$store.dispatch('backtest/atualizar', payload )
        .then(response => {
          this.$swal({
            title: 'Sucesso!',
            text: 'Backtest atualizado com sucesso',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao atualizar backtest',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    getBacktest(id) {
      this.$store.dispatch('backtest/getBacktest', id)
        .then(response => {
          this.backtest = response.data
          // this.montaGraficos()
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Registro não encontrado',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/variables-dark';
  .dark-layout {
    div ::v-deep {
        .b-overlay {
          .bg-light {
            background-color: $theme-dark-body-bg !important;
          }
        }
    }
  }
</style>
<style>
.hidden_header {
  display: none;
}
</style>